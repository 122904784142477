<template>
  <div id="call-view-header-and-footer-202329061354"
       class="call-view-header-and-footer"
  >
    <playbook-title
      v-if="playbook"
      class="d-none d-lg-flex"
      :playbook="playbook"
      @open-playbook-selection-modal="$emit('open-playbook-selection-modal')"
      @delete-call-and-return-to-configurator="$emit('delete-call-and-return-to-configurator')"
    />

    <div class="call-view-controls">
      <div class="d-none d-lg-flex align-items-center justify-content-between">
        <div
          v-if="shouldShowSpeechRecognition"
          class="speech-recognition-block font-size-12"
          role="button"
          @click="$emit('toggle-show-speech-recognition')"
        >
          <toggle-icons
            :is-active="showSpeechRecognition"
            :type="'radio'"
          ></toggle-icons>
          {{ speechRecognitionLabel }}
        </div>
        <div
          class="show-full-text-block font-size-12 pr-0"
          :class="{'pl-0':shouldShowSpeechRecognition}"
          role="button"
          @click="$emit('toggle-full-text-view')"
        >
          <toggle-icons
            :is-active="fullTextView"
            :type="'eye'"
          ></toggle-icons>
          <div>{{ fullTextLabel }}</div>
        </div>
        <div
          class="show-full-text-block font-size-12"
          role="button"
          @click="$emit('toggle-expand-all-items')"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="['collapse-icon', { 'expand': !allItemsExpanded }]" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.206 4.27a1.06 1.06 0 00-1.415 0L3.793 8.885a.875.875 0 000 1.307c.39.36 1.024.36 1.415 0L9.5 6.229l4.292 3.96c.39.36 1.025.36 1.415 0a.875.875 0 000-1.307L10.21 4.268l-.003.002zm4.998 10.153l-4.998-4.615a1.06 1.06 0 00-1.415 0l-4.998 4.615a.875.875 0 000 1.307c.39.36 1.024.36 1.415 0L9.5 11.767l4.292 3.96c.39.36 1.025.36 1.415 0a.875.875 0 000-1.307l-.003.003z" fill="#7F8197"/></svg>
          <div>{{ expandItemsLabel }}</div>
        </div>
      </div>
      <div class="header-top-right-menus">
        <div class="d-flex align-items-center justify-content-between mb-2 mb-md-0">
          <counterpart-display-block
            ref="counterpart-display-block"
            @input="value => $emit('input', value)"
            @clear-crm-data="$emit('clear-crm-data')"
            @download-crm-data="$emit('download-crm-data')"
            class="header-counterparts-block"
          ></counterpart-display-block>

          <!-- meeting bot -->
          <connect-bot
            v-if="!!showShowMeetingBot"
          ></connect-bot>
        </div>

        <air-call
          v-if="canUseAirCall && counterparts && counterparts.length"
          :counterpart="counterparts[0]"
          class="air-call"
        ></air-call>

        <!-- call end button -->
        <div class="end-call-block">
          <div class="call-duration">
            <span class="duration-title">{{ staticText.callDurationLabel }}:</span>
            <span class="duration-value">{{ convertMillisecondsToHHMMSS(callDuration) }}</span>
          </div>

          <call-view-end-button
            id="end-btn-container-footer"
          ></call-view-end-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CounterpartDisplayBlock from "@/apps/call/CallViewCounterpartComponents/CounterpartDisplayBlock"
import ToggleIcons from "@/apps/base/toggleIcons"
import CallViewEndButton from "./CallViewEndButton"
import { mapGetters } from "vuex"
import { currentCallStore } from "@/store/services/callStore"
import { baoDelayService } from "./index"
import AirCall from "@/apps/call/AirCall"
import ConnectBot from "@/apps/call/meeting/ConnectBot.vue"
import PlaybookTitle from "./PlaybookTitle.vue"

export default {
  name: "CallViewHeaderAndFooter",
  components: {
    ToggleIcons,
    CallViewEndButton,
    CounterpartDisplayBlock,
    AirCall,
    ConnectBot,
    PlaybookTitle
  },
  data () {
    return {
      staticTextDefault: {
        showSpeechRecognitionLabel: "Show speech analysis",
        hideSpeechRecognitionLabel: "Hide speech analysis",
        expandAllItemsLabel: "Expand all items",
        collapseAllItemsLabel: "Collapse all items",
        showFullTextLabel: "Show full text",
        hideFullTextLabel: "Hide full text",
        callDurationLabel: "Duration"
      }
    }
  },
  props: {
    showSpeechRecognition: {
      type: Boolean,
      default: false
    },
    fullTextView: {
      type: Boolean,
      default: false
    },
    allItemsExpanded: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      canUseSpeechRecognition: "auth/canUseSpeechRecognition",
      canUseAirCall: "auth/canUseAirCall",
      canUseVideoIntegration: "auth/canUseVideoIntegration"
    }),
    speechRecognitionLabel () {
      return this.showSpeechRecognition ? this.staticText.hideSpeechRecognitionLabel : this.staticText.showSpeechRecognitionLabel
    },
    fullTextLabel () {
      return this.fullTextView ? this.staticText.hideFullTextLabel : this.staticText.showFullTextLabel
    },
    expandItemsLabel () {
      return this.allItemsExpanded ? this.staticText.collapseAllItemsLabel : this.staticText.expandAllItemsLabel
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    playbook () {
      return currentCallStore.playbook
    },
    counterparts () {
      return currentCallStore.counterparts
    },
    callDuration () {
      return currentCallStore.callDuration
    },
    isPreviewMode () {
      return !!this.$route.query.previewMode
    },
    showShowMeetingBot () {
      return !this.isPreviewMode && this.canUseVideoIntegration
    },
    shouldShowSpeechRecognition () {
      return !this.isPreviewMode && this.canUseSpeechRecognition
    }
  },
  methods: {
    ...baoDelayService.methods,
    downloadCrmData () {
      this.$refs["counterpart-display-block"].initiateDownloadCrmData()
    }
  }
}
</script>

<style lang="scss" scoped>

.call-view-header-and-footer {
  background-color: $white;
  box-shadow: 6px 8px 20px 5px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 24px;

  .call-view-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-top-right-menus {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-counterparts-block {
        border-inline: 1px solid $slate20;
        padding-inline: 8px;
      }

      .air-call {
        display: none;
        margin: 0 8px 0;
      }

      .end-call-block {
        padding-left: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .call-duration {
          display: flex;
          align-items: center;
          .duration-title {
            display: none;
          }
          .duration-value {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 4px;
            color: $slate80;
            // The width is set to avoid other components from moving left and right. Not setting this value makes
            // the width dynamic. This is calculated with the estimated max-width when numbers are 66:66:66 as this
            // is the assumed fattest combination of numbers
            width: 62px;
            text-align: center;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 15px;
    margin-bottom: 0;
    margin-top: 8px;

    .call-view-controls {
      width: 100%;
      .header-top-right-menus {
        width: 100%;

        .header-counterparts-block {
          border-inline: unset;
          padding-inline: unset;
        }

        .air-call {
          display: unset;
        }

        .end-call-block {
          flex-direction: row;

          .call-duration {
            margin-right: 40px;
            .duration-title {
              display: unset;
              margin-right: 6px;
              font-weight: bold;
              color: $slate80;
            }
            .duration-value {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: unset;
    justify-items: unset;

    .call-view-controls {
      .header-top-right-menus {
        flex-direction: column;
        align-items: unset;
        .header-counterparts-block {
          width: 100%;
        }

        .air-call {
          margin: 0 0 8px 0;
        }

        .end-call-block {
          padding-left: 0;
          justify-content: space-between;
          width: 100%;

          .call-duration {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.speech-recognition-block,
.show-full-text-block {
  text-align: center;
  padding: 4px 8px;
  background-color: transparent;
  transition: 0.3s ease-in;
  &:hover {
    background-color: $slate08;
  }
}

.collapse-icon {
  transition: 0.3s ease-in;
  &.expand {
    transform: rotate(180deg);
  }
}

</style>
